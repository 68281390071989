import React from 'react'
import * as styles from './section.module.scss'

const Section = ({ id, color = 'white', className, children, ...props }) => {
  return <section {...props} className={`position-relative bg-${color} ${styles.section} py-10 ${className}`}>
    <div id={id} className={`position-absolute ${styles.anchor}`}></div>
    
    <div>
        {children}
    </div>
  </section>
}

export default Section;