import React from 'react'
import * as styles from './footer.module.scss'
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare'
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin'

const Footer = ({ id }) => {
  return <footer className={`${styles.footer} py-4`}>
    <div className="container">
        <div className="row align-items-center">
            <div className="col text-left">
              Copyright © NGEN PRO {new Date().getFullYear()}<br />
              <div className="small">Built by <a href="https://blazing.solutions" target="_blank" rel="noreferrer">Blazing.Solutions</a></div>
            </div>
            <div className="col text-right">
              <a href="https://www.facebook.com/ngenpro" className="text-dark" target="_blank" rel="noreferrer"><FaFacebookSquare size="2rem" className="mx-1" aria-label="Facebook page" /></a>
              <a href="http://www.linkedin.com/company/ngenllc" className="text-dark" target="_blank" rel="noreferrer"><FaLinkedin size="2rem" aria-label="LinkedIn page" /></a>
            </div>
        </div>
    </div>
  </footer>
}

export default Footer;
