import React, { useState, useEffect } from 'react'
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap'
import { Link } from 'gatsby'
import './navbar.scss'
import Logo from '../../images/logo.png'

const NavBar = ({ initialNavVariant = 'dark' }) => {
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const scrollEvent = function () {
      setScrolled(window.scrollY > 0);
    };

    scrollEvent();

    window.addEventListener('scroll', scrollEvent, { passive: true });
    return function cleanup() {
      window.removeEventListener('scroll', scrollEvent);
    }
  }, []);

  return <Navbar bg="transparent" variant={initialNavVariant} expand="lg" fixed="top" className={`${scrolled ? 'navbar-scrolled' : ''} ${expanded ? 'nav-expanded' : ''}`} expanded={expanded} onToggle={ex => setExpanded(ex)} onSelect={() => setExpanded(false)}>
    <Container>
      <Navbar.Brand href="/#home" className="text-primary font-weight-bold"><img src={Logo} alt="NGEN Pro" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavDropdown title="Products">
            <NavDropdown.Item as={Link} to="/products">Our Products</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/distributors">Available From</NavDropdown.Item>
          </NavDropdown>
          
          <Nav.Link href="/promotions/ctf">Promotions</Nav.Link>
          {/* <NavDropdown title="Promotions">
            <NavDropdown.Item as={Link} to="/promotions/ctf">Capture the Flag</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/promotions/monthly">Monthly</NavDropdown.Item>
          </NavDropdown> */}

          <NavDropdown title="About Us">
            <NavDropdown.Item href="/#about">About Us</NavDropdown.Item>
            <NavDropdown.Item href="/#ngensway">NGEN's Way</NavDropdown.Item>
            <NavDropdown.Item href="/newsletters">Newsletters</NavDropdown.Item>
          </NavDropdown>
          
          <Nav.Link href="/#contact">Contact</Nav.Link>
          <Button variant="primary" href="tel:+1-844-643-6776" className="text-uppercase">Call 844 NGEN-PRO</Button>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
};

export default NavBar;