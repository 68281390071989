import React from "react"
import { Helmet } from 'react-helmet'
import '../../sass/index.scss'

import NavBar from '../navbar/navbar'
import Contact from '../contact/contact'
import Footer from '../footer/footer'

const Layout = ({ initialNavVariant, title, children }) => {
  return (
    <div>
      <Helmet>
        <title>{title || 'NGEN Pro - The Future of Performance Chemicals'}</title>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700" rel="stylesheet" type="text/css" />
      </Helmet>

      <NavBar initialNavVariant={initialNavVariant} />
      
      {children}

      <Contact />

      <Footer />
    </div>
  )
}

export default Layout
