import React, { useState } from 'react'
import Section from '../section/section'
import { Form, Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    e.preventDefault();

    setError(false);
    setLoading(true);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': e.target.attributes.name.value, ...form })
    }).then(r => {
        if (!!r.ok) {
          setSuccess(true);
          setForm({});
        }
        else
          setError(true);
        setLoading(false);
      })
      .catch(error => {
        setError(true);
        setLoading(false);
      });

    return false;
  }

  return <Section id="contact" color="dark" className="contact">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase text-light">Contact Us</h2>

          <h4 className="text-primary">Be an NGEN Pro. Give us a call!</h4>
          <h4 className="text-light mb-5">1-844-NGEN-PRO (643-6776)</h4>
        </div>

        <Form name="contact" data-netlify="true" onSubmit={handleSubmit}>
          <input type="hidden" name="form-name" value="contact" />

          <div className="row align-items-stretch mb-5">
            <div className="col-md-6">
              {error && <div className="form-group">
                <p className="alert alert-danger font-weight-bold">
                  Something went wrong while sending your message. Please try sending it again.
                </p>
              </div>}

              <div className="form-group">
                <input className="form-control" name="name" type="text" placeholder="Your Name *" required="required" data-validation-required-message="Please enter your name." value={form?.name || ''} onChange={handleChange} disabled={loading} />
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-group">
                <input className="form-control" name="email" type="email" placeholder="Your Email *" required="required" data-validation-required-message="Please enter your email address." value={form?.email || ''} onChange={handleChange} disabled={loading} />
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-group mb-md-0">
                <input className="form-control" name="phone" type="tel" placeholder="Your Phone *" required="required" data-validation-required-message="Please enter your phone number." value={form?.phone || ''} onChange={handleChange} disabled={loading} />
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-group form-group-textarea mb-md-0">
                <textarea className="form-control" name="message" placeholder="Your Message *" rows={10} required data-validation-required-message="Please enter a message." value={form?.message || ''} onChange={handleChange} disabled={loading}></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-group text-center mb-5 mb-md-0">
                <div id="success"></div>
                <button className="btn btn-primary btn-xl text-uppercase" id="sendMessageButton" type="submit" disabled={loading}>
                  {loading && <FontAwesomeIcon icon={faCog} spin className="mr-2" />}
                  Send Message
                </button>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47549.11806677949!2d-88.14849240714227!3d41.85370361312084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e53e2f983efc7%3A0xa03db05b8c116d5a!2sWheaton%2C%20IL!5e0!3m2!1sen!2sus!4v1621913127886!5m2!1sen!2sus" width="100%" frameBorder="0" style={{ border:0, borderRadius: '8px', minHeight: 300, marginBottom: 0 }} allowFullScreen="" aria-hidden="false" title="Our location"></iframe>
            </div>
          </div>
        </Form>
      </div>

      <Modal size="lg" show={success} onHide={() => setSuccess(false)} centered>
        <Modal.Body>
          <p className="my-3 text-center">Thank you for contacting us. We'll get back to you shortly!</p>
          <div className="text-center">
          <Button variant="primary" onClick={() => setSuccess(false)}>Close</Button>
          </div>
        </Modal.Body>
      </Modal>
    </Section>
}

export default Contact;
